// Here you can add other styles
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_"),
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype",
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@include font-face(Roboto, "../assets/fonts/Roboto", null, null, woff);
@include font-face(RobotoMedium, "../assets/fonts/Robotomedium", null, null, woff);
@include font-face(RobotoBold, "../assets/fonts/Robotobold", null, null, woff);

body {
  font-family: Roboto, sans-serif;

  .modal-body.field-map-adder {
    min-height: 60vh;
  }
}
.mapContainer {
  width: 100%;
  height: calc(100vh - 105px);
}
.preloader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-right: 15px;
    font-size: 35px;
  }
}

.statuser {
  position: absolute;
  right: 45px;
  top: 8px;
  display: block;
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 50em;
  background-color: red;
}
.app-header {
  .dropdown-header.username {
    color: #000;
    background-color: #fff;
    font-size: 14px;
  }
}
.app-body main.main {
  height: 100%;
  background-color: #f7f7f7;
  // margin-left: 250px;

  .fields-statistics {
    position: absolute;
    z-index: 1;
    max-width: 250px;
    max-height: 250px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 5px;
    background-color: #fff;
    padding: 10px;
  }

  .card {
    border-radius: 0;

    .card-header {
      border-radius: 0;
      background-color: #66bb6a;
      color: #fff;
      border-top: 0;
    }
  }

  #accordion {
    .card {
      border: unset;
      border-radius: 0;
      border-bottom: 1px solid #fff;

      .card-header {
        border: unset;
        border-radius: 0;
        background-color: #c8ced3;
        padding: 5px;

        button {
          &:hover {
            text-decoration: none;
          }

          &:focus {
            text-decoration: none;
          }

          h5 {
            font-size: 14px;
            color: #000;
            text-decoration: none;

            i {
              float: right;
            }

            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      .card-body {
        font-size: 14px;
        color: #000;
        padding: 10px;

        p {
          span {
            font-family: RobotoMedium;
          }
        }
      }
    }
  }

  .container-fluid {
    padding: 0;
    height: 100%;
  }
}

.modal .modal-body div#accordion {
  .card {
    border: unset;
    border-radius: 0;
    border-bottom: 1px solid #fff;

    .card-header {
      border: unset;
      border-radius: 0;
      padding: 5px;

      button {
        &:hover {
          text-decoration: none;
        }

        &:focus {
          text-decoration: none;
        }

        h5 {
          font-size: 14px;
          color: #000;
          text-decoration: none;

          i {
            float: right;
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .card-body {
      font-size: 14px;
      color: #000;
      padding: 10px;

      p {
        span {
          font-family: RobotoMedium;
        }
      }
    }
  }
}

.dBlock {
  display: block;
}

.dNone {
  display: none;
}

.sidebar-fixed {
  .sidebar {
    .sidebar-nav {
      ul.nav {
        background-color: #fff;
      }
    }

    .nav-link {
      font-family: RobotoMedium;
    }
  }
}

.NavbarBrandTitle {
  max-width: 425px;
  font-size: 14px;
  color: #2f353a;
}

.FarmInfo {
  position: absolute;
  z-index: 3000;
  background-color: #000;
  top: 0;
  bottom: 50px;
  left: 0;
  right: calc(100% - 400px);
  transition: width 2s;
  transition-timing-function: linear;
  // padding-top: 100px;
  display: none;

  #accordion {
    .card {
      border: unset;
      border-radius: 0;
      border-bottom: 1px solid #fff;

      .card-header {
        border: unset;
        border-radius: 0;
        padding: 5px;
        display: flex;
        align-items: center;

        button {
          &:hover {
            text-decoration: none;
          }

          &:focus {
            text-decoration: none;
          }

          h5 {
            font-size: 14px;
            color: #000;
            text-decoration: none;

            i {
              float: right;
            }

            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      .card-body {
        font-size: 14px;
        color: #000;
        padding: 10px;

        a.check-navigator,
        button.check-navigator {
          max-width: 45px;
          text-align: center;
          display: inline-block;
          border: 0;
          border-radius: 0.25rem;
        }

        p {
          span {
            font-family: RobotoMedium;
          }
        }
      }
    }
  }
}

.logoBig {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: unset;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $brandColor;
  border-radius: 0px;
}

.FarmInfo {
  overflow-y: auto;
  background-color: #f7f7f0;
  color: #000;
  // border-top: 3px solid #2196F3;
  // border-right: 3px solid #2196F3;
  // border-bottom: 3px solid #2196F3;
  font-size: 25px;

  &.show {
    display: block;
    transition: width 2s;
    transition-timing-function: linear;
  }

  table {
    font-size: 13px;
  }

  .info-top {
    padding: 1.2rem 0;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    position: relative;
    color: #00539b;

    i {
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 28px;
      cursor: pointer;
    }

    h4 {
      font-family: RobotoMedium;
      font-size: 18px;
    }

    .info-col {
      display: flex;
      justify-content: space-between;
    }

    p {
      font-size: 14px;
    }
  }

  .info-middle {
    padding: 7.5px 15px;

    .info-item {
      .my-label {
        font-family: RobotoMedium;
        font-size: 16px !important;
        color: #00539b;
      }

      h5 {
        font-size: 18px;
        font-family: RobotoBold;
      }
    }

    .info-col {
      .my-label {
        font-family: Roboto;
        font-size: 14px;
        color: #00539b;
        font-family: RobotoMedium;
      }

      h5 {
        font-size: 18px;
        font-family: RobotoBold;
      }
    }
  }
}

.yandexMap {
  position: relative;

  .navigation-area {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 35px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    padding: 5px;

    .circular-button {
      display: flex;
      margin: 0 10px;
      justify-content: center;
      align-items: center;
      background-color: $white;
      border-radius: 50%;
      border: 0px;
      width: 40px;
      height: 40px;
      color: $black;
      box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
      font-size: 20px;
    }
  }
}

.field-adder {
  .crops-adder {
    border-top: 1px solid #b0adad;
    padding: 10px 0;
  }
}

.fields-table-page,
.users-table-page {
  padding: 0 15px;
  margin-top: 15px;

  div.fields-table {
    .pagination {
      flex-wrap: wrap;
    }

    button.field-adder {
      color: $brandColor;
    }

    .card-header {
      margin-bottom: 10px;
      border-radius: 10px 10px 0 0 !important;
    }

    border: unset;
    box-shadow: 0px 3px 18px 0px rgba(50, 50, 50, 0.1);
    border-radius: 10px !important;

    .card-body {
      border-radius: 10px;
    }
  }
}

#recommendation {
  overflow: auto;
}

// One Field info quarantines style

//** One Field info quarantines style

// Dalolatnoma styles
.book {
  font-family: Tahoma;

  .page {
    width: 210mm;
    min-height: 297mm;
    padding: 2mm;
    margin: 10mm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  .subpage {
    padding: 0.2cm 0.2cm 24px 0.2cm;
    border: 1px;
    height: auto;
    //height: 293mm;
    font-size: 15px;

    .next-check {
      border-top: 1px solid black;
      padding-top: 15px;
    }

    p {
      max-width: 100%;
      word-wrap: break-word;
    }

    .spec-number {
      text-align: center;
    }
  }

  .logo {
    width: 100px;
    height: 120px;
    object-fit: cover;
  }
}

.react-fancybox .thumbnail img {
  max-width: 100% !important;
  width: auto;
}

div[title="Stop drawing"] {
  content: url(https://cdn4.iconfinder.com/data/icons/whsr-january-flaticon-set/128/compass.png) !important;
  height: 28px;
  width: 28px;
  cursor: pointer;
}

// *************************Map styles
// .pest-filter, .seed-filter{

// }
.pest-filter {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 240px;
  height: 32px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
  position: absolute;
  right: 15px;
  top: 15px;
  margin-right: 60px;
}

.placeType-filter {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 240px;
  height: 32px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
  position: absolute;
  left: 15px;
  bottom: 15px;
  margin-right: 60px;
}

.fieldColor-filter {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 240px;
  height: 32px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
  position: absolute;
  left: 270px;
  bottom: 15px;
  margin-right: 60px;
}

.seed-filter {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 240px;
  height: 32px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
  position: absolute;
  right: 280px;
  top: 15px;
  margin-right: 60px;
}

// !*************************Map styles
// *************************Forecast Map styles
.forecast-main {
  position: absolute;
  bottom: 60px;
  font-size: 25px;
  background-color: #fff;
  transition: 0.2;
  color: #006422;
  border-radius: 10px;
  padding: 5px 10px;
}

ul.forecast-list {
  li.forecast-list-item {
    border-right: 1px solid #80b291;

    &:last-child {
      border-right: 0;
    }
  }
}

// !*************************Forecast Map styles
// *************************Lab Check Map styles
.lab-check {
  font-family: "Times New Roman", Times, serif;
  p {
    font-size: 15px;
  }
  .laborant-name {
    margin-top: 15px;
    font-size: 18px;
  }
  .page {
    padding-left: 1.5cm;

    .subpage {
      padding-left: 0;
      padding-bottom: 110px;
      position: relative;
      .qr-code {
        position: absolute;
        left: 0;
        bottom: 0;
      }
      .place-name {
        text-align: center;
      }
      .logo {
        width: 70px;
        height: auto;
        object-fit: unset;
        margin: 10px 0;
      }
    }
  }

  table.lab-check-header {
    th,
    td {
      border: 1px solid black;
    }
  }
}

// !*************************Lab Check Map styles

// div[title="Draw a circle"] {
//   content: url(http://www.pngmart.com/files/4/Moon-PNG-Transparent.png) !important;
//   height: 28px;
//   width: 28px;
//   cursor: pointer;
// }

// div[title="Draw a rectangle"] {
//   content: url(https://www.shareicon.net/data/32x32/2015/07/04/64183_rectangle_256x256.png) !important;
//   height: 28px;
//   width: 28px;
//   cursor: pointer;
// }
// div[title="Добавьте маркер"] {
//   content: url(https://www.shareicon.net/data/32x32/2015/07/04/64183_rectangle_256x256.png) !important;
// height: 28px;
// width: 28px;
//   cursor: pointer;
// }


// *************************Progress Bar styles

.meter {
  box-sizing: content-box;
  height: 10px; /* Can be anything */
  position: relative;
  margin-top: 20px; /* Just for demo spacing */
  background: #e4e7ea;
  border-radius: 25px;
  padding: 10px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}
.meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgb(43, 194, 83);
  background-image: linear-gradient(center bottom, rgb(43, 194, 83) 37%, rgb(84, 240, 84) 69%);
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
}
.meter.going > span:after,
.animate > span > span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}

.animate > span:after {
  display: none;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.orange > span {
  background-image: linear-gradient(#f1a165, #f36d0a);
}

.red > span {
  background-image: linear-gradient(#f0a3a3, #f42323);
}

.nostripes > span > span,
.nostripes > span::after {
  background-image: none;
}

// !*************************Progress Bar styles
// Chat Styles
html,
body,
#root,
.root,
.app {
  height: 100%;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.chat-pageLeft {
  background-color: $white;
  padding-left: 2px;

  .top {
    border-bottom: 2px solid $earth;
    padding: 10px;
    .search-box {
      position: relative;
      margin-bottom: 15px;
      input {
        width: 100%;
        font-size: 18px;
        padding: 5px 0 5px 40px;
      }

      button {
        background-color: unset;
        border: 0;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border: unset;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        color: $thirdSilver;
        font-size: 18px;
        line-height: 21px;
        font-family: RobotoMedium;
        &:focus-within{
          outline: none;
        }
        &.active {
          background-color: unset;
          color: $secondBrand;
        }
      }
    }
  }

  .users-list {
    overflow-y: auto;
    flex-grow: 1;
  }
  .chat-pageLeft__contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 10px;
    width: 100%;
    .pageLeft__img {
      margin-right: 10px;
      flex: 0 0 15%;
      max-width: 15%;
      width: 100%;
      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 15px;
      }
    }

    .pageLeft__content {
      flex: 0 0 85%;
      max-width: 85%;
      width: 100%;
      padding-right: 10px;
      ul {
        li {
          font-size: 14px;
          line-height: 16px;
          color: $secondBlack;

          .pageLeft__text {
            display: flex;
            justify-content: space-between;

            p {
              font-size: 16px;
              line-height: 19px;
              color: $black;
              font-family: RobotoBold;
              margin-bottom: 2px;
            }

            div {
              display: flex;
              align-items: center;
            }

            h6.text-time {
              font-size: 14px;
              line-height: 16px;
              color: $normalSilver;
              margin-right: 10px;
            }

            .online-status {
              width: 15px;
              height: 15px;
              background-color: $fifthBrand;
              border-radius: 50%;
              color: $white;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 10px;
              line-height: 12px;
            }
          }
        }
      }
    }
  }
  .contact__bg {
    background-color: #006422;
    .pageLeft__content {
      flex: 0 0 85%;
      max-width: 85%;
      width: 100%;
      padding-right: 10px;
      ul {
        li {
          color: $white;
         .pageLeft__text{
          h6.text-time{
            color: $white;
          }
          p{
            color: $white;
          }
         }
        }
      }
    }
  }
}
.popover {
  .sendOptions-content.popover-body {
    ul {
      li {
        margin-bottom: 10px;
        svg {
          margin-right: 15px;
          width: 20px;
          path {
            fill: $secondBrand;
          }
        }
      }
    }
  }
}
.chat-page {
  height: 100%;
  width: 100%;
  background-color: $white;
  .chat-container {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
    box-sizing: border-box;
    .chat-wrapper {
      // height: calc(100vh - 120px);
      width: 100%;
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      img.home {
        width: 100px;
        position: fixed;
        top: 15px;
        right: 15px;
        z-index: 100;
        border: 1px solid $silver;
        padding: 10px;
        border-radius: 10px;
      }
      .chat-content {
        flex-grow: 1;
        overflow-y: auto;
        .user-message {
          padding-left: 40px;
          position: relative;
          span.user {
            position: absolute;
            left: -15px;
            bottom: 0;
            display: inline-block;
            bottom: 10px;
            img {
              border-radius: 15px;
              width: 40px;
              height: 40px;
              object-fit: cover;
            }
          }
          ul {
            display: inline-block;
            li {
              word-break: break-all;
              span.message-view {
                display: inline-block;
                font-size: 14px;
                padding: 10px;
                background-color: $lightSilver;
                margin-bottom: 10px;
                border-radius: 5px;
                img {
                  width: 150px;
                }
                .file-preview {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $white;
                    border-radius: 50%;
                    width: 35px;
                    height: 35px;
                    margin-right: 10px;
                    svg.fa-file-alt {
                      display: block;
                      width: 15px;
                      path {
                        fill: $secondBrand;
                      }
                    }
                  }
                }
              }
              &:first-child {
                span {
                  border-radius: 5px 5px 5px 0;
                }
              }
              &:last-child {
                span {
                  border-radius: 0 5px 5px 5px;
                  display: block;
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
      .chat-textarea {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        background-color: $white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .preview-info {
          background-color: $brand;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          padding: 1.5rem 1rem;
          color: $white;
          display: flex;
          justify-content: space-evenly;
          position: absolute;
          top: -72px;
          right: 0;
          left: 0;
          padding-right: 70px;
          .icon-box {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            justify-content: space-between;
            width: 70px;
            .icon {
              border-radius: 50%;
              height: 30px;
              width: 30px;
              background-color: $white;
              color: $black;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .textarea-wrapper {
          width: 100%;
          position: relative;
          textarea {
            border: 1px solid #dedede;
            width: 100%;
            height: 75px;
            max-height: 75px;
            padding: 25px 125px 25px 35px;
            overflow: hidden;
          }
          button {
            position: absolute;
            padding: 10px 15px;
            background-color: $brand;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 20px;
            border: 1px solid #f2f2f2;
            color: $white;
            &.sendOptions {
              right: unset;
              color: $brand;
              left: 10px;
              padding: 0;
              background-color: transparent;
              border: 0;
              svg.fa-paperclip {
                width: 18px;
                &:hover path {
                  fill: $secondBrand;
                }
              }
            }
          }
        }
      }
    }
  }
}

.people-nearby .nearby-user{
  padding: 10px 0;
  border-top: 1px solid #f1f2f2;
  border-bottom: 1px solid #f1f2f2;
  margin-bottom: 20px;
}
.people-nearby .profile-link{
  color: #000;
  font-weight: bold;
}
img.profile-photo-lg{
  height: 50px;
  width: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
}
.contextMenu {
  position: fixed;
  background: white;
  box-shadow: 0px 2px 10px #999999;
  z-index: 1;
  &--option {
      padding: 6px 50px 5px 10px;
      min-width: 160px;
      cursor: default;
      font-size: 12px;
      &:hover {
          background: linear-gradient(to top, #555, #333);
          color: white;
      }

      &:active {
          color: #e9e9e9;
          background: linear-gradient(to top, #555, #444);
      }

      &__disabled {
          color: #999999;
          pointer-events: none;
      }
  }

  &--separator {
      width: 100%;
      height: 1px;
      background: #CCCCCC;
      margin: 0 0 0 0;
  }
}
// !!! Chat Styles
.header-fixed.sidebar-lg-show.sidebar-fixed.aside-menu-fixed.aside-menu-off-canvas.aside-menu-lg-show.aside-menu-lg-show .aside-menu{
  width: 400px;
}
// Dalolatnoma styles
// YMaps{
// z-index: unset!important;
// }
@media only screen and (max-width: 992px) {
  .NavbarBrandTitle {
    display: none;
  }
  .header-fixed.sidebar-lg-show.sidebar-fixed.aside-menu-fixed.aside-menu-off-canvas.aside-menu-lg-show.aside-menu-lg-show .aside-menu{
    width: 100%;
    margin-right: 0;
  }
  .FarmInfo {
    position: fixed;
    top: 55px;
    width: 100%;
    bottom: 0;
  }
}

@media only screen and (max-width: 576px) {

  .sidebar {
    height: 100vh;
  }
  .navbar-brand-full {
    width: 35px !important;
  }
  header.app-header {
    position: absolute;
    z-index: 1020;
    width: unset;
    text-align: center;
    background-color: #fff;
    right: 0;
    top: 60px;
    display: flex;
    flex-direction: column;
    min-height: 160px;
    button.navbar-toggler {
      background-color: #fff;
    }

    ul.navbar-nav {
      // display: none;
      margin-left: unset !important;
      flex-direction: column;
    }
    a.navbar-brand {
      position: static;
      width: unset;
      height: unset;
      margin-left: 0;
      margin-bottom: 5px;
    }
  }
  .app-body {
    margin-top: 0;
  }
  .mapContainer {
    width: 100%;
    height: 100vh !important;
  }
  footer {
    display: none !important;
  }
  // *************************Map styles
  .pest-filter,
  .seed-filter,
  .placeType-filter,
  .fieldColor-filter {
    display: none;
  }

  // !*************************Map styles
  .fields-statistics {
    display: none;
  }

  .field-adder .nav-tabs .nav-item {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;

    .nav-link {
      margin-bottom: 15px;
      border: 1px solid $brandColor;

      &.active {
        background-color: $brandColor;
        color: #fff;
      }
    }
  }
}

