// Variable overrides
$body-bg:    #fff;
$sidebar-bg: #fff;
$sidebar-nav-link-color: #000;
$sidebar-nav-link-icon-color: #000;
$sidebar-nav-link-active-color: #45BF55;
$sidebar-nav-link-active-icon-color: #45BF55;
$sidebar-nav-link-hover-bg: #45BF55;
$sidebar-nav-link-hover-color: #fff;
$sidebar-nav-link-hover-icon-color: #fff;
$sidebar-width: 250px !default;
$sidebar-nav-title-color: #000;
$sidebar-nav-dropdown-color: #000;
$sidebar-nav-dropdown-bg: #dcdcdc;
$navbar-brand-width: 90px;
$brandColor: #45BF55;
$white: #fff;
$black: #000;
$brand: #219653;
$secondBrand: #27AE61;
$thirdBrand: #52C367;
$fourthBrand:#2FAA5D;
$fifthBrand: #21AE5E;
$earth: #F3EFE4;
$darkEarth: #E7E3D7;
$lightEarth: #717B85;
$transition-bezier: 1.000, 0.000, 0.000, 1.000;
$transition-len: 0.35s;
$white: #fff;
$black: #000;
$secondBlack: #4D4D4D;
$normalBlack: #414141;
$silver: #BEC0C2;
$secondSilver: #F3EFE4;
$thirdSilver: #77797E;
$lightSilver: #F0F0F0;
$darkSilver: #696969;
$normalSilver: #808080;
$scrolColor: #C4C4C4;
$brown: #D5CCB6;
