
.removeButton {
  background: red;
  border-radius: 20px;
  width: 20px!important;
  height: 20px!important;
  display: block;
  padding: 0 0 0 5px;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}
