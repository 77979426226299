.global-header{
    background-color:#45bf55;
    padding-top: 0;
    padding-bottom: 0; 
    .logo{
        width: 50px;
        height: 50px;
        object-fit: contain;
    }
    .navbar-nav{
        .nav-item{
            .nav-link{
                color: #fff!important;
                font-size: 18px;
            }
        }
    }
}
.home{
    .slider{
        width: 100%;
        height: calc(100vh - 53px);
        background-image: url('../assets/img/back_home.jpg');
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        * {
            position: relative;
            z-index: 1;
            color: #fff;
        } 
    }
}
.overlay{
    background-color: rgba($color: #000000, $alpha: 0.5);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
